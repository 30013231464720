//获取当前环境
export function isWx() {
//判断当前环境（微信/其他）
  let ua = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger"
}

/**
 * 微信授权
 */
export function getJsCode() {
  const code = getUrlParam('code')
  if (!code) {
    let url = 'http://www.fymuseum.com'
    window.location.replace('https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
      'wxb24ba12263b94cbc' +
      '&redirect_uri=' +
      encodeURIComponent(url) +
      '&response_type=code&scope=snsapi_base&state=login#wechat_redirect');
  }
  return code
}


// 解析URL 参数
export function getUrlParam(name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
  let r = window.location.search.substr(1).match(reg);
  if (r != null) {
    console.log(r);
    return unescape(r[2]);
  }
  return null;
}

