// import request from '@/util/request'
import request from './request'
/**
 * 登录,注册
 * 个人中心  预约
 * 首页
 */
export function configVale(data) {
  return request({
    url: 'api/serve/config/value',
    method: 'post',
    data
  })
}

// 获取验证码
export function getCodeImg() {
  return request({
    url: '/captchaImage',
    method: 'get'
  })
}

// 注册账号
export function signin(data) {
  return request({
    url: '/app/user/register',
    method: 'post',
    data
  })
}

//  登录
export function login (data) {
  return request({
    url: 'app/user/loginByPassword',
    method: 'post',
    data
  })
}

export function login2 (data) {
  return request({
    url: 'app/user/login',
    method: 'post',
    data
  })
}


// 获取个人信息资料
export function  userInfo () {
  return request({
    url: 'app/user/userInfo',
    method: 'get',
  })
}

//  编辑个人资料
export function  editUser (data) {
  return request({
    url: 'app/user/editUser',
    method: 'post',
    data
  })
}

//  修改密码
export function  alterPwd (data) {
  return request({
    url: 'app/user/updatePwd',
    method: 'post',
    data
  })
}

//    短信验证码
export function  msgCode (data) {
  return request({
    url: '/app/user/emailSend',
    method: 'post',
    data
  })
}

// 找回密码
export function  forgetPwd (data) {
  return request({
    url: '/app/user/forgetPwd',
    method: 'post',
    data
  })
}




/**
 *  个人中心预约
 *
 */
//  查询预约日期接口   home/time/list
 export function  makeDate (data) {
  return request({
    url: 'home/time/list',
    method: 'get',
  })
}

// 查询预约时间接口
export function  makeTime (data) {
  return request({
    url: 'home/time/sub/list',
    method: 'post',
    data
  })
}


//  特展预约  列表
export function  unusual (num,size) {
  return request({
    url: `serve/exhibition/list?pageNum=${num}&pageSize=${size}`,
    method: 'get',
  })
}

//  特展预约  详情
export function  unDetail (id) {
  return request({
    url: `serve/exhibition/${id}`,
    method: 'get',
  })
}

// 预约记录
export function  recordList (num,size,data) {
  return request({
    url:`serve/record/list?pageNum=${num}&pageSize=${size}`,
    method:'post',
    data
  })
}


// 我的收藏列表
export function  myColllect (num,size) {
  return request({
    url: `object/collect/list?pageNum=${num}&pageSize=${size}`,
    method: 'get',
  })
}






//  预约证件类型列表
export function  cardList (data) {
  return request({
    url: `app/dict/data/value`,
    method: 'post',
    data
  })
}

//新增预约
export function addOrder (data) {
  return request({
    url:`serve/record/add`,
    method:'post',
    data
  })
}

//  取消预约
export function orderCancel (id) {
  return request({
    url:`serve/record/${id}`,
    method:'DELETE',
  })
}

/**
 *  首页  数据接口
 */

//  首页轮播图
export function Banner () {
  return request({
    url: '/home/banner/list',
    method: 'get',
  })
}

// 滚动条

export function roll () {
  return request({
    url: 'home/banner/roll',
    method: 'get',
  })
}


//  首页 新闻呢资讯
export function HomeNews () {
  return request({
    url: 'consult/news/home/list',
    method: 'post',
  })
}

// 首页 数字典藏
export function collec () {
  return request({
    url: 'object/number/number/list',
    method: 'get',
  })
}



// 首页 本馆公告
export function homeNotice() {
  return request({
    url: 'consult/announcement/list',
    method: 'get',
  })
}


// 首页馆藏精品列表   二级馆藏
// export function homeColl () {
//   return request({
//     url: '/object/boutique/home/list',
//     method: 'post',
//   })
// }

// // 首页馆藏精品列表   一级馆藏
export function homeColl () {
  return request({
    url: '/object/boutique/oneHome/list',
    method: 'post',
  })
}



//  首页 新展推荐
export function homeRecom () {
  return request({
    url: 'exhibition/permanent/home/list',
    method: 'get',
  })
}



// 首页 活动预告
export function foreshow () {
  return request({
    url: 'activity/foreshow/home/list',
    method: 'get',
  })
}

// 首页 获取首页网站访问量
export function ipCount () {
  return request({
    url: 'app/dict/data/ipCount',
    method: 'get',
  })
}
// 首页 友情链接
export function linklist (data) {
  return request({
    url: 'blogroll/blogroll/list',
    method: 'post',
    data
  })
}
