<template>
  <div id="app" :style="headerStyle ? homeBg : bg">
    <router-view></router-view>
    <GoTop></GoTop>
  </div>
</template>

<script>
import GoTop from "./components/go_top/go_top.vue";

export default {
  data() {
    return {
      headerStyle: true,
      homeBg: {
        backgroundColor: "#ffffff"
      },
      bg: {
        backgroundColor: "#F9F9F8"
      }
    };
  },
  created() {
    if (this.$route.fullPath === "/home") {
      this.headerStyle = true;
    } else {
      this.headerStyle = false;
    }
  },
  components: {
    GoTop
  },
  watch: {
    // 监听路由变化
    $route: {
      handler: function(val, oldVal) {
        // console.log(val,'sndksa');
        if (val.fullPath === "/home") {
          this.headerStyle = true;
        } else {
          this.headerStyle = false;
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
#app {
  // background: url('./static/img/bg.png') no-repeat;
  background: #f9f9f8;
  // background-size: 100%;
  // background-color: #F9F9F8;
}
.homeBg {
  background-color: #ffffff;
}
.bg {
  background-color: #f9f9f8;
}

@media only screen and (max-width: 750px) {
  #app {
    background-image: none;
    background-color: #f9f9f8;
  }
}
</style>
