import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/lib/lib-flexible'
import  Mixin from './mixin'
import VueClipboard from 'vue-clipboard2' // 复制文本插件
import install from "./components";  // 全局组件文件
import 'wowjs/css/libs/animate.css'; // css动画样式
import './assets/iconfont/iconfont.css'
import VueAMap from 'vue-amap' // 高德地图
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: store.state.locale, // 语言标识
  messages: {
    'zh': require('./common/lang/zh'),
    'en': require('./common/lang/en')
  },
})

Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: 'ed86f13ab9b7b83e42af2c689a4e2abf'  // 申请的key
});

Vue.use(install)


Vue.prototype.$store = store
Vue.mixin(Mixin)

Vue.use(VueClipboard)

// 配置element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI)

Vue.config.productionTip = false




new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
