module.exports = {
  language: {
    name: '中文'
  },

  navbar: {

    register: 'register',
    //首页
    home: 'Home',

    //概况
    overview: 'General Situation',
    profile: 'Museum Introduction',
    orgzt: 'Institutional framework',
    person: 'Administrator',
    events: 'Events Glance',
    //资讯
    consult: 'Information',
    news: 'News information',
    partybuilding: 'Party Building Special',
    notice: 'Notice',
//展览
    exhibition: 'Exhibition',
    show: 'Permanent exhibition',
    recommendation: 'Exhibition recommendation',
    review: 'Exhibition review',
    overseasexhibition: 'Overseas exhibition',
    museum: 'Panoramic Museum',


//藏品
    collection: 'Collection',
    special: 'Recommendation',
    boutique: 'Collection boutique',
    digital: 'Digital collection',
    total: 'General collection',
    search: 'Library catalog',
    donate: 'Donated Garden',
//研究
    study: 'Research',
    work: 'Journal works',
    paper: 'Thesis',
    project: 'Scientific research item',
    menuType9: 'Academic Committee',
//活动
    fullChain: 'Activity',
    dcrwcac: 'Activity forecast',
    exhibittion: 'Social education Special',
    activity: 'Activity lecture',
    other: 'Other activities',
    //服务
    service: 'Service',
    information: 'Ticket information',
    instructions: 'Tour instructions',
    map: 'Site map',
    products: 'Cultural creative products',
    traffic: 'Traffic routes',
    recommend: 'Volunteer window',
    apply:'Volunteer Application',

    contactus: 'Contact Us',
    sm:"查看更多",
    links:'文博慕课与文物专题',
  },
  //其他

  mess: {
    cr:'3D cultural',
    works:'Periodical',
    thesis:'Thesis',
    times:'It is closed on Monday and normally open on holidays. During this period, it is postponed for one day every Monday.',
    status:'status',
    order:'order',
    need:'Need an appointment',
    noneed:'No appointment required',
    over:'The exhibition is over',
    wechat:'WeChat official',
    web:'Sina Weibo',
    pho:'Consultation telephone',
    account:'Total website visits',
    toacc:'Today\'s visits',
    bq:'Copyright Fuyang Museum',
    num:'Wan ICP Bei No. 18011179',
    mes:'Do not steal the photos or words of this website without permission, otherwise they will be investigated for legal responsibility',
    tiele: 'Title',
    yeartxt: 'Years',
    searchtxt: 'Search',
    name: "Name",
    era: "Era",
    texture: "Texture",
    classification: "Classification",
    ay: 'Any year',
    details: "Details",
    gl: 'Geographical location',
    mc: "Most Clicks",
    cm: "Collect the most",
    size: "Size",
    introduction: 'Introduction',
    rcr: 'Related cultural relics',
    cb: "Change batch",
    download: "Download",
    phone: 'phone',
    inputpho:'Please enter the contact number',
    time:'time',
    dz:'address',
    pro:'exhibition Introduction',
    zp: 'Key exhibits',
    sj: 'Notes',
    nj: 'Interior view of exhibition hall',

    picture: 'Picture',
    pic:'Photos of cultural relics',

    audio: 'Audio',
    video: 'Video',
    shareTo: 'Share To',
    introduce: "introduce",
    pd: 'Product Display',
    solution: 'Solution',
    // ts: "Technical Support",
    suggestions: 'Complaints and suggestions',
    jd: 'complaint telephone number',
    dx: 'Electronic information',
    dw: 'Publicity of Party Affairs',
    more: 'more',
    newsd:"News detail",

    djdt: 'Party Building Trends',
    lookmore: 'See more',
    ms: 'Anhui, located in the middle and lower reaches of the Yangtze River, is one of the birthplaces of Chinese civilization, with beautiful mountains and rivers, beautiful bells and graceful scenery, colorful natural treasures and outstanding people. Over the past ten thousand years, Jiangxi people, who are active, innovative and dedicated, have developed this magical land into an economically developed area, a place of cultural prosperity and a country of integrity, writing a colorful chapter in the history of the development of human civilization.',
    anhui:'Anhui Provincial Museum held the general election meeting of the trade union committee',
    ad:'Collection Announcement of Fuyang Museum',
    ww:'Donated cultural relics',
    tit:'2020 project',
    act:'Not started',
    ing:'Ongoing',
    end:'Has ended',
    activityObject:'Active object',
    activtyTime:'Activity time',
    activtyPlace:'Activity place',
    activtyApply:'Activity apply',
    floor:'floor',
    mf:'Free opening hours',
    rg:'enter',
    tz:'Stop entering',
    bg:'Closed',
    ph:'Appointment / complaint telephone',
    yy:'Appointment telephone',
    ts:'complaint telephone',
    gr:'Personal appointment visit',
    td:'Team appointment visit',

  },
  // 详情模块
  de: {
    rt: 'Release Time',
    source: 'Source',
    vc: 'View Count',
    sc: 'Successful Case',
    particulars: 'Particulars',
    collect: "collect",
    check: "check",
    zb:'招投标类',
    bw:'博物馆类',
    zf:'相关政府部门',
    zy:'公共资源交易中心',
    xz:'资源下载类',
    qt:'其它',
    sy:'首页展示',
  },
  //  分页
  paging: {
    last: 'Last',
    next: 'Next',
    go: 'Go',
    zhgo: 'Go',
    page: 'Page',
    toPage: 'Pgae'
  },
  part:{
    download:'Attachment Download',
    upload:'Attachment upload',
    in:'I want to apply for volunteers',
    insmall: 'I want to apply for a small volunteer',
    dt:'Volunteer dynamics',
    all:'View all',
    tit: 'Welcome to apply to join the general volunteer of Fuyang Museum',
    smalltit:'Welcome to apply to join the small volunteer of Fuyang Museum',
    mess: 'Please ensure that you provide complete and accurate information so that you can receive volunteer information and obtain corresponding services in time.',
    bt: 'The following items with ※ are required',
    name: 'name',
    inputna: 'Please enter your name',
    male: 'Gender',
    inputmale:'Please enter your Gender',

    man: 'man',
    woman: 'woman',
    mz: 'nation',
    inputmz: "Please enter a family name",
    bir: "date of birth",
    inputbir: 'Please enter the date of birth',
    from: 'native place',
    inputfrom: 'Please enter the native place',
    num: "ID number",
    inputnum: "Please enter the ID number",
    reinputnum: 'Fill in the ID number is wrong, please fill in again.',
    phone:'phone',
    inputpho:'Please enter the phone number',
    add: 'postal address',
    inputadd: 'Please enter the postal address',
    address:'Detailed address of current residence',
    inputaddress:'Please enter the detailed address of your current residence',
    email:'E-mail',
    inputemail:'Please enter the E-mail ',
    guardianname:'Name of Guardian',
    inputguardianname:'Please enter the Name of Guardian',
    gurid:'Guardian ID number',
    inputgurid:'Please enter the Guardian ID number',
    relation:'Relationship with Guardian',
    inputrelation:'Please enter the Relationship with Guardian',
    gurpho:'Guardian contact number',
    inputgurpho:'Please enter the contact number of the guardian',
    grade:'School grade',
    inputgrade:'Please enter the School grade',
    work: 'place of work',
    inputwork: 'Please enter the place of work',
    inputqq: 'Please enter the qq',
    reinputqq: 'The QQ number is incorrect. Please fill it in again',
    zw: 'work',
    inputzw: 'Please enter the work',
    wh: 'education level',
    inpuywh: 'Please fill in the education level',
    cz: 'junior high school',
    gz: "senior school",
    dz: "junior college",
    bk: 'regular college course',
    ss: 'master',
    bs: 'doctor',
    gw: 'Service post',
    inputgw: 'Please enter the service position',
    sj: 'service time',
    inputgwsj:'Please select the service time',
    td: 'Resume and expertise',
    inputtd: 'Please enter your resume and expertise',
    by: 'What kind of rewards have you received',
    inputjl: 'Please briefly describe the rewards you have received',

    fw: 'Volunteer Experience',
    inputfw: 'Please briefly describe your volunteering experience',
    jl: 'Please fill in your resume',
    readL: 'I have read',
    gl: 'Please check the volunteer management system',
    zc: 'Articles of Association for volunteers of Fuyang Museum',
    xz: 'Detailed rules for the implementation of the volunteer management system of Fuyang Museum',
    tj: 'Submit',
    qx: 'cancel',
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday'

  },
  self:{
    month:'month',
    day: 'day',
    gozc:"Go register",
    nom:'Forget password',
    hy:'Registered member',
    register:'Register',

    gr:'Personal Center',
    xg:'Modify Avatar',
    yh:'user name',
    fillyh:'enter one user name',
    zl:'ID type',
    zh:'ID Number',
    sj:'phone number',
    yx:'mailbox',
    nl:'age',
    bc:'preservation',
    oldm:'Original password',
    newm:'New password',
    fillm:'Please enter the user password',
    suren:'Confirm password',
    surem:'Confirm new password',
    fillema:'Please enter the verification code',
    log:'Member login',
    login:'Sign in',
    out:'sign out',
    mm:'password',

    dess:'The user name can contain uppercase and lowercase English letters, numbers, Chinese and underline, with a length of 2 to 18 characters',
    mess:'The password can contain uppercase and lowercase English letters, numbers and underscores, with a length of 6 to 16 digits',
    ger:'Personal appointment',
    td:'Team appointment',
    tz:'Special exhibition reservation',
    pho:'Reservation number',
    per:'Appointment person',
    date:'Visit date',
    time:'Admission time',
    phon:'ID number',
    findm:'Retrieve password',
    yzm:' Verification Code',
    yz:'Please set a new password through email verification',
    fail:'Wrong email number',
    codeno:'Incorrect verification code',
    nooldm:'The new password is filled in incorrectly',
    nosurem:'Confirm that the password is filled in incorrectly',
    nosame:'The two passwords are inconsistent. Please fill in the same password',
    succm:'Password modified successfully',
    same:'Keep consistent with the password',
    find:'Used to retrieve the password',
    yhfail:'The user name is incorrect. Please fill it in again',
    mfail:"The password is incorrect. Please fill it in again",
    fill:'Please fill in the verification code',
    phofail:'The telephone number is incorrect. Please fill it in again',
    xy:'Please check the agreement',
    zc:'Please fill in the following information to register as a member',
    des:' The personal data you fill in is protected by Fubo website',
    email:'Please fill in the email verification code',
    hq:'Get verification code',
    succ:'Email verification code sent successfully, please check it!',
    inf:'Basic information',
    mx:"Password modification",
    rec: 'Personal appointment record',
    recs:'Team appointment record',
    sc:"My collection",

    chose: 'Select date',
    xx: 'Fill in information',
    yy: 'Complete the appointment',
    xz: 'Select time',
    sp: 'standby ticket',
    end: 'Has ended',
    ym: 'About full',
    xj: 'New visiting team',
    xh: 'Serial number',
    num: "teams number",
    cz: 'operation',
    delete: 'delete',
    syb: 'previous step',
    xyb: 'next step',
    hz: 'Vigorous style and singing -- the exhibition of civilization in Zhou, Qin, Han and Tang Dynasties',
    tim: 'Exhibition time: July 6, 2021 (Tuesday) - October 10, 2021 (Sunday)',
    xt: 'New visiting team',
    cg: 'New visitors',
    total: 'team total members',
    xm: 'Please fill in your name',
    sf: 'The ID card is filled in incorrectly. Please fill it in again',
    jg: 'The officer card is filled in incorrectly. Please fill it in again',
    tb: 'Taiwan compatriot certificate is filled in incorrectly, please fill it again',
    ga: 'There is an error in filling in the Hong Kong and Macao certificate. Please fill in it again',
    ty: 'Please fill in the number of reservation teams',
    suc: 'Congratulations on your successful appointment!',
    messa: 'You have reserved 1 person to visit the museum. The reservation information is：',
    pz: 'Please take the initiative to keep or screenshot the QR code into your mobile phone so that you can check in as soon as possible！',
    norep: 'Reserved, please do not repeat the appointment',
    failed: 'Appointment failed',
    lxr:'New contact',
    page:'Digital page',
    ts:'The selected appointment time cannot be less than the current time',
    mes:'information',
    xzl:'Please select a contact',
    success:'Reservation application succeeded',
    old: 'The original password is incorrect. Please fill it in again',

    new: 'The new password is incorrect. Please fill it in again',

    qr: 'Confirm that the password is filled in incorrectly, please fill it in again',

    bg:'Saved successfully',
    succe:'login successful ',











  }
}
